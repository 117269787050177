import * as React from "react";
import { navigate } from "gatsby";
import { Auth0Provider } from "@auth0/auth0-react";

// Load tailwind styles
import "./src/styles/global.css";

const onRedirectCallback = (appState) => {
  // Use Gatsby's navigate method to replace the url
  // navigate(appState?.returnTo || "/", { replace: true });

  // We need to set local state for login
  localStorage.setItem("logged_in", true);
  navigate(appState?.returnTo || "/dashboard", { replace: true });
};

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
        audience: process.env.GATSBY_AUTH0_USERADMIN_AUDIENCE,
      }}
    >
      {element}
    </Auth0Provider>
  );
};
